<template>
  <v-row>
    <v-col>
      <v-card :loading="loading" :disabled="loading">
        <v-card class="ml-5 mt-5 mr-5 mb-5">
          <FullCalendar ref="full_calendar" :plugins="plugins" :options="calendarOptions" />
        </v-card>
      </v-card>
      <h2 class="ml-10">{{ $store.getters.translate("unscheduled_projects") }}</h2>
      <table style="border-spacing: 0px; -webkit-border-spacing: 0px; border-collapse: collapse; -webkit-border-collapse:collapse;" class="mt-5 mb-5">
        <tbody id="unscheduled_events_body"/>
      </table>
    </v-col>
  </v-row>
</template>

<script>
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import moment from "moment";

export default {
  components: {
    FullCalendar
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("planner"),
        name: "planner",
        model: "planner",
      },
      calendarOptions: {
        schedulerLicenseKey:'0428499722-fcs-1673088865',
        plugins: [ dayGridPlugin, interactionPlugin, resourceTimelinePlugin ],
        headerToolbar: {
          start: 'today prev,next',
          center: 'title',
          end: 'resourceTimelineWeek, resourceTimelineMonth'
        },
        initialView: 'resourceTimelineMonth',
        views: {
          resourceTimelineWeek: {
            slotDuration: { days: 1 }
          }
        },
        resourceAreaColumns: [
          {
            field: 'title',
            headerContent: 'Project',
          },
        ],
        height: "auto",
        contentHeight: "auto",
        resourceAreaWidth: "300px",
        editable: true,
        eventOverlap: false,
        eventResize: this.handleEventDropResize,
        eventDrop: this.handleEventDropResize,
        eventReceive: this.handleEventReceive,
        resources: this.loadResources,
        events: this.loadEvents,
      },
      loading: false,
      disabled: false,
      resources: [],
      events: []
    }
  },
  created() {
    this.getProjects();
  },
  methods: {
    getProjects() {
      if(this.events.length === 0) {
        this.loading = true;
        this.$http
            .get(this.$store.getters.appUrl + "v2/projects")
            .then((response) => {
              this.loading = false;
              let table_html = '';
              response.data.data.forEach((project) => {
                var project_name = project.name;
                if(project.afe_pef) {
                  project_name += " (" + project.afe_pef + ")";
                }
                if (project.start_date && project.end_date) {
                  this.resources.push({"id": project.id, "title": project_name});
                  this.events.push({
                    "id": project.id,
                    "resourceId": project.id,
                    "allDay": true,
                    "start": moment(project.start_date).toDate(),
                    "end": moment(project.end_date).add(1, 'days').toDate(),
                    "title": project_name,
                    "url": "/projects/" + project.id
                  });
                } else {
                  table_html += '<tr id="project_' + project.id + '"><td class="pa-2 draggable-class" style="cursor: pointer;" data-event=\'{"id": "' + project.id +
                      '", "resourceId": "' + project.id + '", "title": "' + project_name + '", "url": "/projects/' + project.id + '"}\'>' + project_name + '</td></tr>';
                }
              });
              this.resources.push({"id": "999999", "title": "Add project +"});
              this.$refs.full_calendar.getApi().refetchResources();
              this.$refs.full_calendar.getApi().prev();
              this.$refs.full_calendar.getApi().next();

              document.getElementById("unscheduled_events_body").innerHTML = table_html;

              new Draggable(document.getElementById("unscheduled_events_body"), {
                itemSelector: '.draggable-class'
              });
            })
            .catch((error) => {
              this.loading = false;
              if (error.response.status === 403) {
                this.$toasted.error(this.$store.getters.translate('no_access'))
              } else {
                this.$toasted.error(error);
              }
            });
      }
    },
    loadResources(fetchInfo, successCallback) {
      successCallback(this.resources);
    },
    loadEvents(fetchInfo, successCallback) {
      successCallback(this.events);
    },
    handleEventDropResize(info) {
      let from = moment(info.event._instance.range.start).format("YYYY-MM-DD");
      let till = moment(info.event._instance.range.end).subtract(1, 'days').format("YYYY-MM-DD");
      this.sendRequest(info.event._def.publicId, from, till);
      for (let i = 0; i < this.events.length; i++) {
        if(this.events[i].id == info.event._def.publicId) {
          this.events[i].start = moment(info.event._instance.range.start).toDate();
          this.events[i].end = moment(info.event._instance.range.end).toDate();
          break;
        }
      }
    },
    handleEventReceive(info) {
      document.getElementById('project_' + info.event._def.publicId).style.display = 'none';
      let from = moment(info.event._instance.range.start).format("YYYY-MM-DD");
      let till = moment(info.event._instance.range.end).format("YYYY-MM-DD");
      this.sendRequest(info.event._def.publicId, from, till);
      this.resources.push({"id": info.event._def.publicId, "title": info.event._def.title});
      this.events.push({
        "id": info.event._def.publicId,
        "resourceId": info.event._def.publicId,
        "allDay": true,
        "start": moment(info.event._instance.range.start).toDate(),
        "end": moment(info.event._instance.range.end).add(1, 'days').toDate(),
        "title": info.event._def.title,
        "url": "/projects/" + info.event._def.publicId
      });
      info.event.remove();
      this.$refs.full_calendar.getApi().refetchResources();
      this.$refs.full_calendar.getApi().prev();
      this.$refs.full_calendar.getApi().next();
    },
    sendRequest(id, from, till) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/update-project-date", { id: id, from: from, till: till })
          .then(() => {
            this.$toasted.success(this.$store.getters.translate("success"));
            this.getProjects();
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
  },
}
</script>

<style>
.fc-day-sun, .fc-day-sat {
  background-color: #f2f2f2;
}
</style>